<template>
  <section class="fancy-slider">
    <b-container>
      <b-row align-h="center">
        <b-col class="" cols="12" xl="10">
          <div class="fancy-slider__wrapper d-flex flex-column" :style="payload.section_color ? {'background-color': payload.section_color} : ''">
            <h3 class="fancy-slider__title">{{ payload.title }}</h3>
            <div class="fancy-slider__swiper">
              <div class="swiper mySwiper">
                <div class="mt-3 mb-2 fancy-slider__swiper-wrapper swiper-wrapper">
                  <div class="fancy-slider__card swiper-slide" :class="{ 'is-expanded': expandedSlide === index }" v-for="(slide, index) in payload.slides" :key="index" ref="slides" @click="toggleDescription(index)">
                    <h5 class="fancy-slider__card-title" :style="[slide.color_slide ? { backgroundColor: slide.color_slide } : {}, slide.color_font ? { color: slide.color_font } : {}]">{{ slide.title }}</h5>
                    <div class="fancy-slider__card-box d-flex flex-column align-items-center" :style="slide.color_slide ? {'background-color': slide.color_slide} : ''">
                      <div class="fancy-slider__label" v-if="slide.tag">
                        <span>
                          <span class="hash">#</span>{{ slide.tag }}
                        </span>
                      </div>
                      <img class="fancy-slider__card-img" :class="{ 'fancy-slider__card-img--small': payload.small_size }" :src="slide.image.url" :alt="slide.image.alt" />
                      <div class="fancy-slider__content" :class="{ 'is-expanded': expandedSlide === index }">
                        <div class="fancy-slider__description" :class="{ 'is-expanded': expandedSlide === index }" :style="[slide.color_font ? { color: slide.color_font } : {}]" ref="description" v-html="slide.text"></div>
                        <div class="fancy-slider__more" :style="[slide.color_slide ? { backgroundColor: slide.color_slide } : {}, slide.color_font ? { color: slide.color_font } : {}]">
                          {{ expandedSlide === index ? 'Zwiń' : 'Rozwiń' }}
                          <svg :class="{'rotate': expandedSlide === index}" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="chevron-down">
                              <path id="Vector" d="M6 9L12 15L18 9" stroke="#111111" :style="[ slide.color_font ? { stroke: slide.color_font } : {}]" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </g>
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="fancy-slider__nav">
                <div class="d-flex">
                  <div class="fancy-slider__nav-arrows mr-4">
                    <svg class="fancy-slider__nav-left" width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M20 8.42871L12 16.4287L20 24.4287" stroke="#111111" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <svg class="fancy-slider__nav-right" width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 8.42871L20 16.4287L12 24.4287" stroke="#111111" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </div>
                  <svg v-if="payload.autoplay_slider && isPlaying" class="fancy-slider__nav-pause ml-3" @click="toggleAutoplay" width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.6667 25.7619C12.1333 25.7619 13.3333 24.5619 13.3333 23.0952V9.76188C13.3333 8.29521 12.1333 7.09521 10.6667 7.09521C9.2 7.09521 8 8.29521 8 9.76188V23.0952C8 24.5619 9.2 25.7619 10.6667 25.7619ZM18.6667 9.76188V23.0952C18.6667 24.5619 19.8667 25.7619 21.3333 25.7619C22.8 25.7619 24 24.5619 24 23.0952V9.76188C24 8.29521 22.8 7.09521 21.3333 7.09521C19.8667 7.09521 18.6667 8.29521 18.6667 9.76188Z" fill="#111111"/>
                  </svg>
                  <svg v-if="payload.autoplay_slider && !isPlaying" class="fancy-slider__nav-play ml-3" @click="toggleAutoplay" width="32" height="20" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.44721 0.723607C0.782312 0.391156 0 0.874652 0 1.61803V14.382C0 15.1253 0.782312 15.6088 1.44721 15.2764L14.2111 8.89443C14.9482 8.5259 14.9482 7.4741 14.2111 7.10557L1.44721 0.723607Z" fill="#111111"/>
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center align-items-center fancy-slider__video" v-if="payload.video" :style="payload.section_color ? {'background-color': payload.section_color} : ''">
            <div class="fancy-slider__video-box">
              <iframe width="560" height="315" :src="payload.video" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import Swiper from 'swiper';
import { Navigation, Autoplay } from 'swiper/modules';
import 'swiper/swiper-bundle.min.css';
export default {
  name: "FancySlider",
  props: ['payload'],
  data() {
    return {
      expandedSlide: null,
      isPlaying: true,
      duration: null,
      startTimer: null,
      clickable: true,
      distanceRatio: null,
    };
  },
  mounted() {
    this.swiper = new Swiper('.mySwiper', {
      modules: [Navigation, Autoplay],
      slidesPerView: 1,
      loop: true,
      slidesOffsetBefore: 0,
      slidesOffsetAfter: 0,
      spaceBetween: 0,
      speed: this.payload.autoplay_slider ? 10000 : 2000,
      centeredSlides: true,
      allowTouchMove: false,
      breakpoints: {
        576: {
          centeredSlides: false,
          slidesPerView: 'auto'
        },
      },
      navigation: {
        nextEl: '.fancy-slider__nav-right',
        prevEl: '.fancy-slider__nav-left',
      },
      autoplay: this.payload.autoplay_slider ? {
        delay: 0,
        disableOnInteraction: true,
      } : false,
    });
  },
  methods: {
    toggleDescription(index) {
      this.expandedSlide = this.expandedSlide === index ? null : index;
      if(this.expandedSlide === index && this.isPlaying) {
        this.swiper.autoplay.stop();
        this.isPlaying = false;
      } else {
        this.scrollTopSection();
        this.swiper.autoplay.start();
        this.isPlaying = true;
      }
    },
    toggleAutoplay() {
      if(this.isPlaying) {
        this.swiper.autoplay.stop();
        this.isPlaying = false;
      } else {
        this.swiper.autoplay.start();
        this.isPlaying = true;
      }
    },
    scrollTopSection() {
      const section = document.querySelector('.fancy-slider');
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }
  },
  beforeDestroy() {
    if (this.swiper) {
      this.swiper.destroy(true, true);
    }
  },
}
</script>

<style lang="scss">
.fancy-slider {
  padding-bottom: 70px;
  padding-top: 70px;
  background: $main-bg;
  color: $main-color;

  @include media-breakpoint-down(sm){
    padding: 70px 0;
  }

  &__swiper {
    overflow-y: visible;
    overflow-x: clip;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    //& .mySwiper > .swiper-wrapper {
    //  transition-timing-function : linear;
    //}
  }

  & .swiper {
    overflow: visible!important;
  }

  & .swiper-slide {
    overflow: visible!important;
  }

  &__wrapper {
    position: relative;
    padding: 64px;
    background-color: $main-yellow;
    border-radius: 20px;
    //height: 925px;

    @include media-breakpoint-down(sm){
      margin-bottom: 24px;
      padding: 42px 20px;
    }
  }

  &__title {
    font-size: 1.75em;
    font-weight: 700;
    line-height: 120%;
    margin-bottom: 40px;
    @include media-breakpoint-down(lg){
      margin-bottom: 24px;
    }
  }

  &__label {
    position: absolute;
    top: 30px;
    right: 30px;
    padding: 8px;
    background-color: $white;
    border-radius: 4px;
    box-shadow: -1px 2px 8px 0px rgba(0, 0, 0, 0.15);

    & span {
      font-size: .875em;
      font-weight: 500;
      letter-spacing: 0.028px;
    }

    & .hash {
      font-size: 1em;
      font-weight: 500;
      letter-spacing: 0.028px;
    }
  }

  &__card {
    width: 352px;
    transition: transform 0.3s ease;

    @include media-breakpoint-down(sm){
      width: 300px;
    }

    &:nth-child(3n+1) {
      transform-origin: top;
      transform: rotate(-.6deg);
      z-index: 1;

      @include media-breakpoint-down(sm){
        transform: rotate(0);
      }
    }

    &:nth-child(3n+2) {
      transform-origin: top;
      transform: rotate(1.5deg) translateX(2%);
      z-index: 2;

      @include media-breakpoint-down(sm){
        transform: rotate(0);
      }
    }

    &:nth-child(3n) {
      transform: rotate(0deg);
      z-index: 3;
    }

    &.is-expanded {
      z-index: 10;
    }

    &:hover {
      cursor: pointer;
    }

    &-title {
      display: inline-block;
      background-color: $white;
      padding: 8px 24px;
      border-radius: 16px 16px 0px 0px;
      margin-bottom: -1px;
      box-shadow: -1px 2px 8px 0px rgba(0, 0, 0, 0.15);
    }

    &-box {
      position: relative;
      background-color: $white;
      border-radius: 0px 16px 16px 16px;
      padding: 24px;
      box-shadow: -1px 6px 8px 0px rgba(0, 0, 0, 0.15);

      @include media-breakpoint-down(sm){
        border-radius: 0px 0px 16px 16px;
        box-shadow: -1px 6px 8px 0px rgba(0, 0, 0, 0.15);
      }
    }

    &-img {
      width: 100%;
      height: 304px;
      object-fit: cover;
      margin-bottom: 24px;
      border-radius: 8px;

      &--small {
        width: 180px;
        height: 180px;
        object-fit: contain;
      }
    }
  }

  &__content {
    position: relative;
    min-height: 192px;
  }

  &__description {
    max-height: 192px;
    overflow: hidden;
    transition: all 0.3s ease;

    &.is-expanded {
      max-height: unset;
    }

    & p {
      font-size: .88em;
      font-style: normal;
      font-weight: 500;
      line-height: 25px;
      margin-bottom: 0;
    }
  }

  &__more {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    font-size: 1.125em;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0.036px;
    background-color: $white;
    padding-top: 8px;

    &:hover {
      cursor: pointer;
    }

    & svg {
      transition: transform 0.3s ease;
    }

    & .rotate {
      transform: rotate(180deg);
    }
  }

  &__nav {
    //position: absolute;
    //bottom: 64px;
    //right: 64px;
    margin-top: 40px;
    display: flex;
    justify-content: flex-end;
  }

  .fancy-slider__nav-play {
    margin-top: 6px;
  }

  .fancy-slider__nav-left,
  .fancy-slider__nav-right,
  .fancy-slider__nav-pause,
  .fancy-slider__nav-play {

    &:hover {
      cursor: pointer;
    }
  }

  .fancy-slider__video {
    background-color: $main-yellow;
    padding: 64px;
    border-radius: 20px;
    margin-top: 24px;

    @include media-breakpoint-down(sm){
      padding: 32px 20px;
    }

    &-box {
      @include media-breakpoint-up(xl){
        width: auto;
      }

      @include media-breakpoint-down(xl){
        width: 100%;
      }
    }

    & iframe {
      border-radius: 20px;
      width: 735px;
      height: 450px;

      @include media-breakpoint-up(xl){
        width: 735px;
      }

      @include media-breakpoint-down(xl){
        width: 100%;
      }

      @include media-breakpoint-down(lg){
        height: 350px;
      }

      @include media-breakpoint-down(sm){
        height: 200px;
      }
    }
  }
}
</style>
