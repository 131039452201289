<template>
    <section class="logo-gallery">
        <b-container>
          <b-row align-h="center">
            <b-col class="logo-gallery__wrapper d-flex flex-column" cols="12" xl="10">
              <div v-if="payload.title">
                <h3 class="logo-gallery__title">{{ payload.title }}</h3>
              </div>
              <div class="d-flex logo-gallery__slider" v-if="payload.gallery.length > 0">
                <picture class="" v-for="(img, i) in payload.gallery" :key="i">
                  <img :src="img.url" :alt="img.alt" />
                </picture>
              </div>
              <div v-if="payload.title_second">
                <h3 class="logo-gallery__title logo-gallery__title--sec">{{ payload.title_second }}</h3>
              </div>
              <div class="d-flex logo-gallery__slider" v-if="payload.gallery_2.length > 0">
                <picture class="" v-for="(img, i) in payload.gallery_2" :key="i">
                  <img :src="img.url" :alt="img.alt" />
                </picture>
              </div>
            </b-col>
          </b-row>
        </b-container>
    </section>
</template>

<script>
    export default {
        name: "LogoGallery",
        props: ['payload'],
        mounted() {},
    }
</script>

<style lang="scss" scoped>
    .logo-gallery{
      padding-bottom: 70px;
      padding-top: 70px;
      background: #F2F8FF;
      color: #393947;

      @include media-breakpoint-down(sm){
        padding: 70px 12px;
      }
      &__wrapper {
        gap: 40px;
        padding: 64px;
        border-radius: 20px;
        background-color: #fff;

        @include media-breakpoint-down(md){
          padding: 32px;
        }
      }

      &__slider {
        overflow: hidden;
        flex-wrap: wrap;
        row-gap: 24px;
        column-gap: 56px;

        @include media-breakpoint-down(lg){
          flex-direction: column;
          align-items: center;
        }
      }

      &__title {
        font-size: 1.75em;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        margin-bottom: 0;

        &--sec {
          margin-top: 40px;
        }
      }

        picture {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 8px;

            img {
                //width: 100%;
                //height: 100%;
                //max-width: 160px;
                //max-height: 64px;
                object-fit: contain;
                max-width: 200px;

                @include media-breakpoint-up(md){
                    max-height: 80px;
                }

                @include media-breakpoint-up(lg){
                    max-width: 432px;
                    max-height: 74px;
                }
            }
        }
    }
</style>
