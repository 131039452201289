<template>
  <section :class="payload.grey ? 'background' : ''">
    <b-container>
      <b-row>
        <b-col class="content_container" v-html="payload.content"></b-col>
      </b-row>
      <b-row v-if="payload.phases.length > 0" class="mt-7">
        <b-col v-for="(phase, index) in payload.phases" :key="index" sm="12" lg="4">
          <b-row class="align-items-center text-center text-xl-left">
            <b-col sm="12" lg="12" md="12" xl="6"><div class="name_container text-center m-auto">{{ phase.name }}</div></b-col>
            <b-col sm="12" lg="12" md="12" xl="6" class="phase_text mt-2 mb-5 mt-xl-0 mb-lg-0">{{ phase.description }}</b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
export default {
  name: "TextPhases",
  props: ['payload']
}
</script>

<style lang="scss">
.content_container {
  h3 {
    margin-bottom: 3rem !important;
  }
}
</style>

<style scoped lang="scss">
section {
  padding-top: 96px;
  padding-bottom: 96px;

  &.background {
    background-color: rgba(0, 0, 0, 0.1);
  }
}
.phase_text {
  font-size: 18px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0em;
}
.name_container {
  font-size: 36px;
  font-weight: 500;
  line-height: 205px;
  letter-spacing: 0em;

  width: 205px;
  height: 205px;
  border-radius: 100%;
  border: 1px solid $black;
}
</style>
