<template>
  <section class="simple-text-video">
    <b-container>
      <b-row align-h="center">
        <b-col class="content" cols="12" lg="12" v-html="payload.text"></b-col>
        <b-col class="content" cols="12" lg="10">
          <div class="video-listing-item">
            <iframe width="1057" height="642" :src="payload.video" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
        </b-col>
      </b-row>
      <b-row align-h="center" class="text-center button__container">
        <b-col cols="12" lg="12" v-if="payload.button.link">
          <button class="button__container-video">
            <a :href="payload.button.link" v-html="payload.button.text"></a>
          </button>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
export default {
  name: "TextWithVideo",
  props: ['payload'],
}
</script>

<style lang="scss">
section.simple-text-video{
  background: #e5e5e5;
  color: $black;
  padding-top: 96px;
  padding-bottom: 96px;

  @include media-breakpoint-up(lg){
    padding-top: 104px;
    padding-bottom: 104px;
  }

  .button__container {
    margin-top: 96px;

    &-video {
      background-color: #007CC2;
      padding: 21px 32px;
      border-radius: 80px;
      border: none;

      &:hover {
        transform: scale(103%);
        transition: transform .2s;
      }

      & a {
        text-decoration: none;
        color: white;
      }
    }
  }

  .content{
    h3 {
      margin-bottom: 48px
    }

    *:last-child {
      margin-bottom: 0;
    }
  }
}

.video-listing-item{
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  padding-bottom: 56.25%;
  margin: 74px auto 96px auto;

  iframe{
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
}
</style>
