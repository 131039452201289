<template>
    <ul v-if="sm" class="social_media">
        <li v-if="sm.facebook">
            <a :href="sm.facebook" target="_blank">
                <icon :icon="dark ? 'facebook-dark' : 'facebook'" />
            </a>
        </li>
        <li v-if="sm.instagram">
            <a :href="sm.instagram" target="_blank">
                <icon :icon="dark ? 'instagram-dark' : 'instagram'" />
            </a>
        </li>
        <li v-if="sm.twitter">
            <a :href="sm.twitter" target="_blank">
              <icon :icon="dark ? 'twitter-dark' : 'twitter'" />
            </a>
        </li>
        <li v-if="sm.youtube">
            <a :href="sm.youtube" target="_blank">
              <icon :icon="dark ? 'youtube-dark' : 'youtube'" />
            </a>
        </li>
    </ul>
</template>

<script>
    import Icon from './../utility/Icon';

    export default {
        name: "SocialMedia",
        components: {
            Icon,
        },
        props:{
          dark:{
            type: Boolean,
            default: false
          }
        },
        computed: {
            sm() {
                return this.$store.getters.setting('social_media')
            }
        },
    }
</script>

<style scoped lang="scss">
    .social_media {
        list-style: none;
        margin-bottom: 0;
        padding-left: 0;

        li {
            display: inline-block;
            margin-right: 1.5rem;

            a{ display: flex; }
            &:last-child { margin-right: 0; }
        }
    }
</style>

<style lang="scss">
    .social_media{
        a{
            svg { width: 42px; }
            
            &:hover{
                svg path{ fill: $primary; }
            }
        }
    }
</style>