<template>
  <section class="participant-slider">
    <b-container>
      <b-row align-h="center">
        <b-col class="" cols="12" xl="10">
          <div class="participant-slider__wrapper d-flex flex-column" :style="payload.section_color ? {'background-color': payload.section_color} : ''">
            <h3 class="participant-slider__title">{{ payload.title }}</h3>
            <div class="participant-slider__swiper">
              <div class="swiper swiper-participant">
                <div class="swiper-wrapper">
                  <div class="participant-slider__card swiper-slide" v-for="(slide, index) in payload.slides" :key="index" ref="slides">
                    <div class="participant-slider__content">
                      <div class="participant-slider__description" v-html="slide.text"></div>
                    </div>
                    <h5 class="participant-slider__card-title">{{ slide.title }}</h5>
                    <h5 class="participant-slider__card-school">{{ slide.school }}</h5>
                  </div>
                </div>
              </div>
              <div class="participant-slider__nav">
                <div class="d-flex">
                  <div class="participant-slider__nav-arrows">
                    <svg class="participant-slider__nav-left" width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M20 8.42871L12 16.4287L20 24.4287" stroke="#111111" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <svg class="participant-slider__nav-right" width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 8.42871L20 16.4287L12 24.4287" stroke="#111111" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </div>
<!--                  <svg  class="fancy-slider__nav-pause ml-3" @click="toggleAutoplay" width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                    <path d="M10.6667 25.7619C12.1333 25.7619 13.3333 24.5619 13.3333 23.0952V9.76188C13.3333 8.29521 12.1333 7.09521 10.6667 7.09521C9.2 7.09521 8 8.29521 8 9.76188V23.0952C8 24.5619 9.2 25.7619 10.6667 25.7619ZM18.6667 9.76188V23.0952C18.6667 24.5619 19.8667 25.7619 21.3333 25.7619C22.8 25.7619 24 24.5619 24 23.0952V9.76188C24 8.29521 22.8 7.09521 21.3333 7.09521C19.8667 7.09521 18.6667 8.29521 18.6667 9.76188Z" fill="#111111"/>-->
<!--                  </svg>-->
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import Swiper from 'swiper';
import {Autoplay, Navigation} from 'swiper/modules';
import 'swiper/swiper-bundle.min.css';
export default {
  name: "ParticipantSlider",
  props: ['payload'],
  data() {
    return {
      expandedSlide: null,
      isPlaying: true,
    };
  },
  mounted() {
    this.swiper = new Swiper('.swiper-participant', {
      modules: [Navigation, Autoplay],
      slidesPerView: 1,
      loop: true,
      slidesOffsetBefore: 2,
      slidesOffsetAfter: 2,
      spaceBetween: 20,
      speed: 2000,
      breakpoints: {
        576: {
          centeredSlides: false,
          slidesPerView: 1.2,
          spaceBetween: 10,
        },
        960: {
          slidesOffsetBefore: 2,
          slidesOffsetAfter: 50,
          spaceBetween: 0,
          slidesPerView: 'auto'
        }
      },
      navigation: {
        nextEl: '.participant-slider__nav-right',
        prevEl: '.participant-slider__nav-left',
      },
      // autoplay: {
      //   delay: 3000,
      //   disableOnInteraction: true,
      // },
    });
  },
  methods: {
    toggleAutoplay() {
      if (this.isPlaying) {
        this.swiper.autoplay.stop();
      } else {
        this.swiper.autoplay.start();
      }
      this.isPlaying = !this.isPlaying;
    },
  },
  beforeDestroy() {
    if (this.swiper) {
      this.swiper.destroy(true, true);
    }
  },
}
</script>

<style scoped lang="scss">
.participant-slider {
  padding-bottom: 70px;
  padding-top: 70px;
  background: $main-bg;
  color: $main-color;

  @include media-breakpoint-down(sm) {
    padding: 70px 0;
  }

  &__swiper {
    overflow-y: visible;
    overflow-x: clip;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  & .swiper {
    overflow: visible!important;
  }

  & .swiper-wrapper {
    align-items: center;
  }

  & .swiper-slide {
    overflow: visible!important;
  }

  &__wrapper {
    position: relative;
    padding: 64px;
    background-color: $main-yellow;
    border-radius: 20px;

    @include media-breakpoint-down(sm){
      margin-bottom: 24px;
      padding: 42px 20px;
    }
  }

  &__title {
    font-size: 1.75em;
    font-weight: 700;
    line-height: 120%;
    margin-bottom: 40px;
    @include media-breakpoint-down(lg){
      margin-bottom: 24px;
    }
  }

  &__text {
    font-size: 1.059em;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    margin-bottom: 24px;
    @include media-breakpoint-down(lg){
      margin-bottom: 8px;
    }
  }

  &__label {
    position: absolute;
    top: 30px;
    right: 30px;
    padding: 8px;
    background-color: $white;
    border-radius: 4px;
    box-shadow: -1px 2px 8px 0px rgba(0, 0, 0, 0.15);

    & span {
      font-size: .875em;
      font-weight: 500;
      letter-spacing: 0.028px;
    }

    & .hash {
      font-size: 1em;
      font-weight: 500;
      letter-spacing: 0.028px;
    }
  }

  &__card {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 576px;
    height: 100%;
    min-height: 272px;
    padding: 24px 48px;
    border-radius: 16px;
    background-color: $white;
    box-shadow: -1px 2px 8px 0px rgba(0, 0, 0, 0.15);
    overflow: hidden;

    @include media-breakpoint-down(sm){
      width: 100%;
      padding: 24px 16px;
    }

    &:nth-child(3n+1) {
      transform-origin: top;
      transform: rotate(-.6deg);
      z-index: 1;

      @include media-breakpoint-down(sm){
        transform: rotate(0);
      }
    }

    &:nth-child(3n+2) {
      transform-origin: top;
      transform: rotate(1.5deg) translateX(2%);
      z-index: 2;

      @include media-breakpoint-down(sm){
        transform: rotate(0);
      }
    }

    &:nth-child(3n) {
      transform: rotate(0deg);
      z-index: 3;
    }

    &-img {
      z-index: -1;
      position: absolute;
      bottom: 0;
      right: 0;
      max-width: 200px;
      object-fit: cover;
      transform: translate(15%, 15%);
    }

    &-title {
      margin-top: 16px;
      font-size: 1em;
      font-style: normal;
      font-weight: 700;
      line-height: 25px;
    }

    &-school {
      margin-bottom: 0;
      font-size: 1em;
      font-style: normal;
      font-weight: 700;
      line-height: 25px;
      color: $secondary;
    }
  }

  &__description {
    font-size: 1em;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
  }

  &__nav {
    //position: absolute;
    //bottom: 64px;
    //right: 64px;
    margin-top: 40px;
    display: flex;
    justify-content: flex-end;
  }

  .participant-slider__nav-left,
  .participant-slider__nav-right,
  .participant-slider__nav-pause {

    &:hover {
      cursor: pointer;
    }
  }
}
</style>
