<template>
  <section>
    <b-container>
      <b-row>
        <b-col class="text-center" lg="6" md="6" sm="12" v-for="(v,i) in payload.videos" :key="i">
          <h4 class="mb-5">{{ v.title }}</h4>
          <div class="video-listing-item">
            <iframe width="560" height="315" :src="v.url" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
export default {
  name: "Videos",
  props: ['payload']
}
</script>

<style scoped lang="scss">
section {
  margin-top: 96px;
  margin-bottom: 96px;
}

@include media-breakpoint-down(lg) {
  section {
    margin-bottom: 56px;
  }
}

h4 {
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
}

.video-listing-item{
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  padding-bottom: 56.25%;

  iframe{
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
}
</style>
