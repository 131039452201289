<template>
  <section class="field-text" :class="[{'hide-top-margin': payload.hide_top_margin}]">
    <b-container>
      <b-row align-h="center">
        <b-col cols="12" xl="10" class="d-flex justify-content-center field-text__wrapper">
          <div v-if="payload.text" class="field-text__content w-100" v-html="payload.text"></div>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
export default {
  name: "FieldText",
  props: ['payload'],
  methods: {}
}
</script>

<style lang="scss">
.field-text{
  position: relative;
  background: $main-bg;
  color: $main-color;
  padding-top: 70px;
  padding-bottom: 70px;
  z-index: 2;

  &.hide-top-margin {
    padding-top: 0;
  }

  .container {
    position: relative;
  }

  .row {
    @include media-breakpoint-down(sm){
      margin: 0;
    }
  }

  &__wrapper {
    padding: 64px;
    background: $white;
    border-radius: 20px;

    @include media-breakpoint-down(lg){
      padding: 32px 20px;
    }
  }

  &__content {

    & h2 {
      color: $secondary;
      font-size: 1.5em;
      font-style: normal;
      font-weight: 700;
      line-height: 30px;
      margin-bottom: 16px;
    }

    & h3 {
      font-size: 1.25em;
      font-style: normal;
      font-weight: 700;
      line-height: 30px;
      margin-bottom: 4px;
    }

    & p {
      font-size: 1em;
      font-style: normal;
      font-weight: 500;
      line-height: 30px;
      margin-bottom: 16px;
    }

    & a {
      font-size: 1em;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
      color:  $main-color;
      text-decoration: underline;
      word-break: break-word;
    }
  }
}
</style>
