<template>
    <div>
        <component v-for="(b,i) in blocks" :is="'block_' + b.acf_fc_layout" :key="i" :payload="b"></component>
    </div>
</template>

<script>
    import Header from './blocks/Header'
    import Icons from "./blocks/Icons";
    import LogoGallery from "./blocks/LogoGallery";
    import Contact from "./blocks/Contact";
    import SimpleText from "./blocks/SimpleText";
    import Faq from './blocks/Faq';
    import FancyAccordion from "@/components/blocks/FancyAccordion";
    import TextColumns from "@/components/blocks/TextColumns";
    import Timeline from "@/components/blocks/Timeline";
    import TextPhases from "@/components/blocks/TextPhases";
    import FancyCircle from "@/components/blocks/FancyCircle";
    import Videos from "@/components/blocks/Videos";
    import IconsContent from "@/components/blocks/IconsContent";
    import RegisterForm from "@/components/blocks/RegisterForm";
    import RegisterStarterForm from "@/components/blocks/RegisterStarterForm";
    import RegisterFormExcerpt from "@/components/blocks/RegisterFormExcerpt";
    import IconsContentList from "./blocks/IconsContentList";
    import OurCoaches from '@/components/blocks/OurCoaches';
    import VoteFinalists from "@/components/blocks/VoteFinalists";
    import TextWithVideo from "@/components/blocks/TextWithVideo";
    import FancyCircleWithImageDescription from "@/components/blocks/FancyCircleWithImageDescription";
    import MaterialsForDownload from "@/components/blocks/MaterialsForDownload";
    import Quotes from "./blocks/Quotes";
    import Hero from "@/components/blocks/Hero.vue";
    import TitleTextIcons from "@/components/blocks/TitleTextIcons.vue";
    import HeroImages from "@/components/blocks/HeroImages.vue";
    import Steps from "@/components/blocks/Steps.vue";
    import Video from "@/components/blocks/Video.vue";
    import FancySlider from "@/components/blocks/FancySlider.vue";
    import TextSlider from "@/components/blocks/TextSlider.vue";
    import ParticipantSlider from "@/components/blocks/ParticipantSlider.vue";
    import WinnersCards from "@/components/blocks/WinnersCards.vue";
    import FieldText from "@/components/blocks/FieldText.vue";
    import HeroImagesTwo from "@/components/blocks/HeroImagesTwo.vue";
    import SliderVideo from "@/components/blocks/SliderVideo.vue";
    import ListMaterials from "@/components/blocks/ListMaterials.vue";
    import HeroTitleIcons from "@/components/blocks/HeroTitleIcons.vue";

    export default {
        name: "Blocks",
        components: {
            'block_contact': Contact,
            'block_icons': Icons,
            'block_icons_content': IconsContent,
            'block_icons_content_list': IconsContentList,
            'block_videos': Videos,
            'block_our_coaches': OurCoaches,
            'block_fancy_circle': FancyCircle,
            'block_timeline': Timeline,
            'block_content_columns': TextColumns,
            'block_logo_gallery': LogoGallery,
            'block_faq': Faq,
            'block_text': SimpleText,
            'block_text_phases': TextPhases,
            'block_header': Header,
            'block_fancy_accordion': FancyAccordion,
            'block_register_form': RegisterForm,
            'block_register_starter_form': RegisterStarterForm,
            'block_register_form_excerpt': RegisterFormExcerpt,
            'block_vote_for_finalists': VoteFinalists,
            'block_text_with_video': TextWithVideo,
            'block_fancy_circle_with_image_description': FancyCircleWithImageDescription,
            'block_materials_for_download': MaterialsForDownload,
            'block_quotes': Quotes,
            'block_hero': Hero,
            'block_title_with_text_icons': TitleTextIcons,
            'block_hero_with_images': HeroImages,
            'block_steps': Steps,
            'block_fancy_slider': FancySlider,
            'block_video': Video,
            'block_text_slider': TextSlider,
            'block_participant_slider': ParticipantSlider,
            'block_winners_cards': WinnersCards,
            'block_field_text': FieldText,
            'block_hero_with_images_two': HeroImagesTwo,
            'block_slider_video': SliderVideo,
            'block_list_materials_v2': ListMaterials,
            'block_hero_with_title_graphics': HeroTitleIcons
        },
        props: {
            blocks: {
                type: Array,
                required: true,
            },
        },
    }
</script>

<style scoped>

</style>
