<template>
  <section class="video">
    <b-container>
      <b-row align-h="center">
        <b-col class="video__wrapper" cols="12" xl="10">
          <h4 class="video__title">{{ payload.title }}</h4>
          <div class="video__film d-flex justify-content-center">
            <iframe width="560" height="315" :src="payload.video" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
export default {
  name: "Video",
  props: ['payload']
}
</script>

<style scoped lang="scss">
.video {
  padding-bottom: 70px;
  padding-top: 70px;
  background: $main-bg;
  color: $main-color;

  @include media-breakpoint-down(sm){
    padding: 70px 12px;
  }

  &__wrapper {
    border-radius: 20px;
    padding: 64px;
    background-color: $main-blue;

    @include media-breakpoint-down(sm){
      padding: 42px 20px;
    }
  }

  &__title {
    font-size: 1.75em;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    margin-bottom: 38px;
  }

  &__film {

    iframe {
      border-radius: 20px;
      width: 100%;
      height: 100%;
      max-width: 736px;
      min-height: 450px;

      @include media-breakpoint-down(md){
        min-height: 300px;
      }

      @include media-breakpoint-down(xs){
        min-height: 200px;
      }
    }
  }
}
</style>
