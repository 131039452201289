<template>
  <section class="winners-cards">
    <b-container>
      <b-row align-h="center">
        <b-col cols="12" xl="10" class="d-flex justify-content-between flex-column flex-lg-row winners-cards__wrapper p-0">
          <b-col class="winners-cards__card content d-flex justify-content-between flex-column" v-for="(card, index) in payload.cards" :key="index">
            <div class="winners-cards__card-box d-flex flex-column">
              <img v-if="card.img && card.img.url" class="content__logo" :src="card.img.url" :alt="card.img.alt" />
              <h3 v-if="card.title" class="mb-0 mt-1">{{card.title}}</h3>
              <p v-if="card.text" class="margin-bottom">{{card.text}}</p>
            </div>
            <p v-if="card.category" class="mb-0">{{card.category}}</p>
          </b-col>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
export default {
  name: "WinnersCards",
  props: ['payload'],
  methods: {}
}
</script>

<style lang="scss" scoped>
.winners-cards{
  position: relative;
  background: $main-bg;
  color: $main-color;
  padding-top: 48px;
  padding-bottom: 48px;
  z-index: 2;

  .container {
    position: relative;
  }

  .row {
    @include media-breakpoint-down(sm){
      margin: 0;
    }
  }

  &__wrapper {
    gap: 32px;
  }

  &__card {
    position: relative;
    border-radius: 20px;
    background: $white;
    padding: 24px 20px 20px 20px;
    gap: 12px;

    & img {
      width: 76px;
      height: 46px;
      object-fit: cover;
    }

    h3 {
      font-size: 1.25em;
      font-style: normal;
      font-weight: 700;
      line-height: 25px;
    }

    p {
      font-size: 1em;
      font-style: normal;
      font-weight: 500;
      line-height: normal;

      &.margin-bottom {
        margin-bottom: 38px;
      }
    }

    &-box {
      gap: 12px;
    }
  }
}
</style>
