<template>
    <header class="position-relative" :class="{
        'header-short': variant === 'short',
        'header-normal': variant === 'normal'
    }" :style="$store.state.contrast ? 'background-image: linear-gradient(360deg, rgba(31, 31, 31, 0.8) 0%, rgba(31, 31, 31, 0.56) 100%), url('+image+')' : 'background-image: linear-gradient(360deg, rgba(31, 31, 31, 0.56) 0%, rgba(31, 31, 31, 0.16) 100%), url('+image+')'">
        <b-container> 
            <b-row class="align-items-center">
                <b-col :cols="12" :lg="7">
                    <h2 v-html="title"></h2>
                </b-col>
            </b-row>
            <b-row v-if="link" class="mt-5">
                <b-col :cols="12" :lg="7" class="d-flex justify-content-start">
                    <b-link :href="link.url" :target="link.target">
                        <b-button variant="primary">{{ link.title }}</b-button>
                    </b-link>
                </b-col>
            </b-row>
        </b-container>

        <div class="babol">
          <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="80" height="80" rx="40" fill="white"/>
            <g clip-path="url(#clip0)">
              <path d="M19.7045 23.6818L40.0909 41.7159L60.4773 23.6818" stroke="#1B1718" stroke-width="3" stroke-linejoin="round"/>
              <path d="M19.7045 42.5L40.0909 60.5341L60.4773 42.5" stroke="#1B1718" stroke-width="3" stroke-linejoin="round"/>
            </g>
            <defs>
              <clipPath id="clip0">
                <rect width="50" height="69" fill="white" transform="translate(15 5)"/>
              </clipPath>
            </defs>
          </svg>
        </div>
    </header>
</template>

<script>
    export default {
        name: "PageHeader",
        props: {
            variant: {
                type: String,
                validator: val => ['short', 'normal'].includes(val),
                default: 'normal',
            },
            title: String,
            link: Object,
            image: String
        }
    }
</script>

<style scoped lang="scss">
    header {
        display: flex;
        flex-direction: row;
        align-items: center;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        color: $white;

        .babol {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateY(50%) translateX(-50%);
        }
    }
</style>

<style lang="scss">
    header {
        &.header-short{
            min-height: 280px;

            @include media-breakpoint-up(lg){
                min-height: 480px;
            }
        }

        &.header-normal {
            padding-top: 136px;
            padding-bottom: 136px;

            @include media-breakpoint-up(lg){
                min-height: calc(100vh - 128px);
                padding-top: 160px;
                padding-bottom: calc(160px + 4.75vw);
            }
        }
    }
</style>