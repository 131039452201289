<template>
  <div class="icons-list__slide swiper-slide">
    <div class="icons-list__slide-box">
        <b-col cols="12" class="pl-0">
          <h5 class="icons-list__slide-title mb-3">{{ noNbsp(payload.title) }}</h5>
        </b-col>
        <div>
          <p class="icons-list__slide-text mb-2">{{ noNbsp(payload.members) }}</p>
          <a class="icons-list__slide-link" :href="payload.link" target="_blank">{{ noNbsp(payload.school) }} ({{ payload.region }})</a>
        </div>
          <b-col class="d-flex justify-content-center icons-list__slide-video" sm="12" lg="12" cols="12" v-if="isExpanded && payload.youtube">
              <div class="video">
                  <iframe width="560" height="315" :src="payload.youtube" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
          </b-col>
        <p v-if="isExpanded" class="icons-list__slide-description text-center text-lg-left">
          {{ payload.description }}
        </p>
<!--          <b-row class="justify-content-center mb-4 text-center" v-if="payload.youtube">-->

<!--          </b-row>-->
        <b-col cols="12" lg="1" class="button mt-4 mt-lg-0  pr-0 d-flex justify-content-end">
          <div v-show="isHovered && !isExpanded" class="align-items-center" @mouseleave="isHovered = false">
            <p class="m-0 mr-2">O zespole</p>
            <img alt="chevron-right-icon" @click="isExpanded=true" role="button"
                 src="../../assets/media/icons/chevron-right.svg"/>
          </div>
        </b-col>
    </div>
  </div>
</template>

<script>
export default {
  name: "ExpandableItem",
  props: ['payload', 'colorIndex'],
  data() {
    return {
      isExpanded: true,
      isHovered: false,
    }
  },
  methods: {
    noNbsp(text) {
      const reg = new RegExp(String.fromCharCode(160), "g");
      return text.replace(reg,' ');
    }
  },
  computed: {
    activeClass() {
      switch (this.colorIndex) {
        case 0:
          return 'active-health';
        case 1:
          return 'active-safety';
        case 2:
          return 'active-climate';
        case 3:
          return 'active-integration';
        default:
          return {};
      }
    }
  }
}
</script>

<style scoped lang="scss">
.active {
  &-health {

  }

  &-safety {

  }

  &-climate {

  }
  &-integration {

  }
}

.button {
  min-width: 10rem;

  & * {
    display: flex;
  }

  & p {
    white-space: nowrap;
  }
}

a {
  color: inherit;
}

.video {
    width: 448px;
    height: 272px;
    border-radius: 20px;
    overflow: hidden;
    position: relative;

    @media screen and (max-width: 580px) {
        width: 300px;
        height: 200px;
    }

    iframe {
        width: 100%;
        height: 100%;
    }
}

.icons-list__slide {
  background-color: $white;
  border-radius: 16px;
  padding: 32px 24px 48px 24px;
  max-width: 576px;
  width: 100%;
  color: $main-color;
  height: auto;

  @include media-breakpoint-down(sm){
    padding: 24px 12px;
  }

  &-box {
    display: flex;
    flex-direction: column;
  }

  &-title {
    font-size: 1.415em;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  &-text {
    font-size: .94em;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  &-link {
    display: inline-block;
    font-size: .94em;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration: underline;
  }

  &-video {
    margin-top: 42px;
  }

  &-description {
    margin-top: 32px;
    font-size: .94em;
    font-style: normal;
    font-weight: 500;
    line-height: 25px;
  }
}
</style>
