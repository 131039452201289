<template>
  <section class="faq">
    <b-container>
      <b-row v-if="payload.title">
        <b-col class="text-center">
          <h3 class="faq__title">{{ payload.title }}</h3>
        </b-col>
      </b-row>
      <b-row align-h="center">
        <b-col cols="12" xl="10">
          <div class="accordion" role="tablist">
            <div v-for="(qa, index) in payload.faq" :key="index" no-body>
              <div class="accordion-tab d-flex flex-row justify-content-between" v-b-toggle="'accordion-' + index">
                <h4 class="accordion-title">{{ qa.question }}</h4>
                <div class="plus">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line x1="12.4287" y1="8.99205e-08" x2="12.4287" y2="24" stroke="black" stroke-width="4"/>
                    <line x1="24" y1="12" x2="-1.74846e-07" y2="12" stroke="black" stroke-width="4"/>
                  </svg>
                </div>
                <div class="minus">
                  <svg width="24" height="4" viewBox="0 0 24 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line x1="24" y1="2" x2="-1.74846e-07" y2="2" stroke="black" stroke-width="4"/>
                  </svg>
                </div>
              </div>
              <b-collapse :id="'accordion-' + index" accordion="my-accordion" role="tabpanel">
                <div class="content mt-2 mb-4" v-html="qa.answer"></div>
              </b-collapse>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
export default {
  name: "Faq",
  props: ['payload']
}
</script>

<style scoped lang="scss">
.faq {
  padding-bottom: 70px;
  padding-top: 70px;
  background: #F2F8FF;
  color: #393947;

  @include media-breakpoint-down(sm){
    padding: 70px 12px;
  }

  &__title {
    font-size: 1.75em;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin-bottom: 32px;
  }
}

.accordion {
  display: flex;
  flex-direction: column;
  gap: 16px;

  @include media-breakpoint-down(sm){
    margin-left: -12px;
    margin-right: -12px;
  }

  &-tab {
    padding: 16px 64px;
    background-color: #fff;
    border-radius: 8px;
    min-height: 58.6px;
    align-items: center;
    gap: 16px;

    @include media-breakpoint-down(lg){
      padding: 16px 32px;
    }
  }

  &-title {
    margin-bottom: 0;
    font-size: 1.125em;
    font-style: normal;
    font-weight: 700;
  }
}

.collapsed {
  .plus { display: block; }
  .minus { display: none; }
}
.not-collapsed {
  .plus { display: none; }
  .minus { display: block; }
}

.content {
  padding: 16px 64px;
  background-color: #fff;
  border-radius: 8px;

  @include media-breakpoint-down(lg){
    padding: 16px 32px;
  }

  & p {
    font-size: 1.125em;
    font-style: normal;
    font-weight: 700;
  }
}
</style>
