import { render, staticRenderFns } from "./TextWithVideo.vue?vue&type=template&id=b11f1870&"
import script from "./TextWithVideo.vue?vue&type=script&lang=js&"
export * from "./TextWithVideo.vue?vue&type=script&lang=js&"
import style0 from "./TextWithVideo.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports