<template>
    <section class="icons">
        <b-container>
            <b-row align-h="center">
              <b-col class="icons__container d-flex flex-column" cols="12" xl="10">
                <div>
                  <h3 class="icons__title">{{ payload.title }}</h3>
                </div>
                <div class="icons__content d-flex flex-column flex-sm-row justify-content-between flex-wrap">
                  <div class="d-flex flex-column text-center" v-for="(icon, index) in payload.icons" :key="index">
                    <div>
                      <img v-if="icon.icon === 'heart'" src="../../assets/media/icons/heart.svg" alt="Heart icon" />
                      <img v-if="icon.icon === 'shield'" src="../../assets/media/icons/shield.svg" alt="Shield icon" />
                      <img v-if="icon.icon === 'plant'" src="../../assets/media/icons/plant.svg" alt="Plant icon" />
                      <img v-if="icon.icon === 'integration'" src="../../assets/media/icons/integration.svg" alt="Integration icon" />
                    </div>
                    <h6 class="mb-4 mb-md-0">{{ icon.name }}</h6>
                  </div>
                </div>
              </b-col>
            </b-row>
        </b-container>
    </section>
</template>

<script>
    export default {
        name: "Icons",
        props: ['payload']
    }
</script>

<style scoped lang="scss">
  .icons {
    padding-bottom: 70px;
    padding-top: 70px;
    background: #F2F8FF;
    color: #141414;

    @include media-breakpoint-down(sm){
      padding: 70px 12px;
    }

    &__container {
      padding: 64px;
      background-color: #fff;
      border-radius: 20px;

      @include media-breakpoint-down(lg){
        margin-bottom: 32px;
        padding: 48px 32px;
      }
    }

    &__content {
      padding: 16px;
      gap: 24px;
    }

    &__title {
      font-size: 1.75em;
      font-weight: 700;
      line-height: 120%;
      margin-bottom: 40px;
      @include media-breakpoint-down(lg){
        margin-bottom: 24px;
      }
    }
  }
    img {
      max-width: 128px;
    }

    h6 {
      font-size: 1.125em;
      font-weight: 500;
      line-height: normal;
      margin-top: 24px;
    }
</style>
