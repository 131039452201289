<template>
  <section class="timeline">
    <b-container>
      <b-row align-h="center">
        <b-col class="timeline__wrapper d-flex flex-column" cols="12" xl="10">
          <b-container>
            <b-row>
              <b-col>
                <h3 class="timeline__title">{{ payload.title }}</h3>
              </b-col>
            </b-row>
          </b-container>
          <div class="d-none d-lg-block">
            <b-container>
              <b-row>
                <b-col v-for="(box,index) in payload.phases" :key="'phase_top_' + index">
                  <div v-if="index % 2 ===  0" v-html="box.description" class="timeline__description"></div>
                </b-col>
              </b-row>
            </b-container>
            <div class="hr_container">
              <b-container>
                <b-row>
                  <b-col v-for="(_,index) in payload.phases" :key="'phase_num_' + index">
                    <svg class="svg" width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="8" cy="8.42871" r="7" fill="#A18037" stroke="#A18037" stroke-width="2"/>
                    </svg>
                  </b-col>
                </b-row>
              </b-container>
            </div>
            <b-container>
              <b-row>
                <b-col v-for="(box,index) in payload.phases" :key="'phase_bottom_' + index" class="mt-2">
                  <div v-if="index % 2 ===  1" v-html="box.description" class="timeline__description"></div>
                </b-col>
              </b-row>
            </b-container>
          </div>
          <!-- tablet -->
          <div class="d-lg-none d-sm-block d-none">
            <b-container>
              <b-row>
                <b-col>
                  <b-row class="align-content-between h-100">
                    <b-col cols="12" v-for="(box,index) in payload.phases" :key="'phase_top_' + index">
                      <div v-if="index % 2 ===  1" v-html="box.description" class="timeline__description"></div>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col class="vr_container">
                  <b-row class="align-content-around h-100">
                    <b-col cols="12" v-for="(_,index) in payload.phases" :key="'phase_num_' + index" class="d-flex justify-content-center p-0 z-10">
                      <svg class="svg" width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="8" cy="8.42871" r="7" fill="#A18037" stroke="#A18037" stroke-width="2"/>
                      </svg>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col>
                  <b-row class="align-content-around h-100">
                    <b-col cols="12" v-for="(box,index) in payload.phases" :key="'phase_bottom_' + index" class="mt-2">
                      <div v-if="index % 2 ===  0" v-html="box.description" class="timeline__description"></div>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-container>
          </div>
          <!-- mobile -->
          <div class="d-sm-none d-block">
            <b-container>
              <b-row class="align-content-around h-100">
                <b-col cols="12" v-for="(box,index) in payload.phases" :key="'phase_top_' + index" class="mb-3">
                  <div v-html="box.description" class="timeline__description"></div>
                </b-col>
              </b-row>
            </b-container>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
export default {
  name: "Timeline",
  props: ['payload']
}
</script>

<style scoped lang="scss">
.timeline {
  padding-bottom: 70px;
  padding-top: 70px;
  background: #F2F8FF;
  color: #141414;

  @include media-breakpoint-down(sm){
    padding: 70px 12px;
  }

  & .svg {
    z-index: 10;
  }

  &__title {
    font-size: 1.75em;
    font-weight: 700;
    line-height: 120%;
  }

  &__wrapper {
    background-color: #FCC157;
    border-radius: 20px;
    padding: 64px;

    @include media-breakpoint-down(sm){
      padding: 42px 20px;
    }
  }

  &__description {
    font-size: .95em!important;
    margin-bottom: -9px!important;
    & p {
      font-size: .95em!important;
      margin-bottom: -4px!important;

    }
  }
}
h3 {
  margin-bottom: 5rem;
}
.hr_container {
  &:after {
    display: block;
    content: ' ';
    width: 100%;
    height: 2px;
    background: #A18037;
    transform: translateY(-570%);
    margin: 0 12px;
  }
}
.vr_container {
  &:after {
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    content: ' ';
    height: 100%;
    width: 1px;
    background: $black;
  }
}
.number {
  background: $white;
  position: relative;;
  z-index: 1;
  width: 64px;
  height: 64px;
  line-height: 64px;
  border: 1px solid $black;
  border-radius: 100%;
  font-family: Samsung Sharp Sans;
  font-size: 24px;
  font-weight: 500;
  margin: 0 auto;
}
</style>
