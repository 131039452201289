<template>
  <div>
    <section class="steps">
      <b-container>
        <b-row align-h="center">
          <b-col class="steps__wrapper d-flex flex-column" cols="12" xl="10">
            <h3 class="steps__title">{{ payload.title }}</h3>
            <p class="steps__text" v-if="payload.text">{{ payload.text }}</p>
            <div class="d-flex flex-column flex-md-row">
              <b-col class="steps__card active" sm="12" md="6" lg="4" v-for="(c,i) in payload.steps" :key="i">
                <h5 class="mb-4 position-relative">{{ c.title }}</h5>
                <p class="steps__description">{{ c.text }}</p>
              </b-col>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </div>
</template>

<script>

export default {
  name: "Steps",
  props: ['payload']
}
</script>

<style scoped lang="scss">
.steps {
  padding-bottom: 70px;
  padding-top: 70px;
  background: $main-bg;
  color: $main-color;

  @include media-breakpoint-down(sm){
    padding: 70px 12px;
  }

  &__wrapper {
    padding: 64px;
    background-color: $main-yellow;
    border-radius: 20px;
    gap: 40px;

    @include media-breakpoint-down(sm){
      padding: 42px 20px;
    }
  }

  &__title {
    font-size: 1.75em;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    margin-bottom: 0;
  }

  &__text {
    font-size: 1.125em;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
  }

  &__card {
    background-color: $white;
    padding: 40px 16px;
    border-radius: 16px;
    flex: 1 1 auto;
    box-shadow: -1px 2px 8px 0px rgba(0, 0, 0, 0.15);
    transition: all .6s ease;

    &:first-child {
      z-index: 1;
      transform: rotate(-1.192deg);

      &:hover {
        transform: rotate(1.192deg);
      }
    }

    &:nth-child(2) {
      z-index: 2;
      transform: rotate(3.317deg);

      &:hover {
        transform: rotate(-3.317deg);
      }
    }

    &:nth-child(3) {
      z-index: 3;
      transform: rotate(-3.178deg);

      &:hover {
        transform: rotate(3.178deg);
      }
    }
  }

  &__description {
    font-size: 1.08em;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: 0.036px;
  }
}

h5 {
  font-size: 1.5em;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.096px;
}
</style>
