<template>
  <div>
  <section class="text-columns">
    <b-container>
      <b-row align-h="center">
        <b-col class="text-columns__wrapper d-flex flex-column" cols="12" xl="10">
          <h3 class="text-columns__title mb-5">{{ payload.title }}</h3>
          <div class="d-flex flex-column flex-md-row">
            <b-col class="text-columns__card mb-5" sm="12" md="6" lg="4" v-for="(c,i) in payload.cols" :key="i">
              <h5 class="mb-4 position-relative">{{ c.title }}</h5>
              <div class="text-columns__description" v-html="c.content"></div>
            </b-col>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
  </div>
</template>

<script>

export default {
  name: "TextColumns",
  props: ['payload']
}
</script>

<style scoped lang="scss">
.text-columns {
  padding-bottom: 70px;
  padding-top: 70px;
  background: #F2F8FF;
  color: #141414;

  @include media-breakpoint-down(sm){
    padding: 70px 12px;
  }

  &__wrapper {
    padding: 64px 24px;
    background-color: #BDD4EF;
    border-radius: 20px;

    @include media-breakpoint-down(sm){
      padding: 70px 20px;
    }
  }

  &__title {
    margin-left: 40px;

    @include media-breakpoint-down(sm){
      margin-left: 0;
    }
  }

  &__card {
    padding: 48px 16px;
    border-radius: 16px;
    color: #fff;
    flex: 1 1 auto;
    transition: all .6s ease;

    &:first-child {
      background-color: #007CC2;
      z-index: 1;
      transform: rotate(-1.192deg);

      &:hover {
        transform: rotate(1deg);
      }
    }

    &:nth-child(2) {
      background-color: #FCC157;
      color: #141414;
      z-index: 2;
      transform: rotate(3.317deg);

      &:hover {
        transform: rotate(1deg);
      }
    }

    &:last-child {
      background-color: #FF4337;
      z-index: 3;
      transform: rotate(-3.178deg);

      &:hover {
        transform: rotate(0deg);
      }
    }
  }

  &__description {
    font-size: 1.06em;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: 0.036px;
  }
}

  h5 {
    font-size: 1.5em;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.096px;
  }
</style>
