<template>
  <section class="overflow-hidden">
    <b-container>
      <b-row>
        <b-col class="text-center header">
          <h3 v-if="payload.title">{{ payload.title }}</h3>
        </b-col>
      </b-row>
      <b-row class="flex-nowrap align-items-start" id="quotes-slider">
        <b-col cols="12" v-for="(q, index) in payload.quotes" :key="index" :id="'quote-' + index"
               :order="calculateIndex(index, payload.quotes.length)" class="quote d-flex flex-column"
               :class="{'transition-right': transitionRight,
               'transition-left': transitionLeft,
               'transition': transition,
                'blur': calculateIndex(index, payload.quotes.length) === 3
               }">
          <div v-html="q.quote"/>
          <h4>{{ q.participant }}</h4>
          <h5>{{ q.supervisor }}</h5>
          <QuoteIcon class="icon"/>
        </b-col>
      </b-row>
      <b-row class="justify-content-end">
        <ChevronLeft class="button" :onClick="handleGoLeft"/>
        <ChevronRight class="button" :onClick="handleGoRight"/>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import QuoteIcon from '../utility/icons/QuoteIcon'
import ChevronLeft from '../utility/icons/ChevronLeft'
import ChevronRight from '../utility/icons/ChevronRight'

export default {
  name: "Quotes",
  props: ['payload'],
  components: {ChevronLeft, ChevronRight, QuoteIcon},
  data() {
    return {
      indexCorr: 1,
      transitionRight: false,
      transitionLeft: false,
      transition: false,
    }
  },
  methods: {
    calculateIndex(index, totalLength) {
      if(index + this.indexCorr > totalLength) {
        return index + this.indexCorr - totalLength;
      } else {
        return index + this.indexCorr;
      }
    },
    calculateCorrection(step, totalLength) {
      if (this.indexCorr + step > totalLength) {
        this.indexCorr = step
      } else if (this.indexCorr + step < 1) {
        this.indexCorr = totalLength + (this.indexCorr + step)
      } else {
        this.indexCorr = this.indexCorr + step
      }
      console.log(this.indexCorr)
    },
    handleGoRight() {
      this.transitionRight = true;
      this.transitionLeft = false;
      this.transition = true;
    },
    handleGoLeft() {
      this.transitionRight = false;
      this.transitionLeft = true;
      this.transition = true;
    },
  },
  mounted() {
    document.querySelector("#quotes-slider").addEventListener('transitionend', () => {
      if (!this.transition && (this.transitionRight || this.transitionLeft)) {
        const step = this.transitionLeft ? 1 : -1;
        this.calculateCorrection(step, this.payload.quotes.length);
        this.transitionRight = this.transitionLeft = false;
      }
      if (this.transition) {
        this.transition = false;
      }
    });
  }
}
</script>

<style scoped lang="scss">
section {
  margin-bottom: 104px;
  margin-top: 97px;

  .header {
    margin-bottom: 48px;
  }

  .transition-right {
    transform: translateX(calc(-100% - 60px));
  }

  .transition-left {
    transform: translateX(calc(100% + 60px));
  }

  .transition {
    transition: transform 1s ease-in-out;
  }

  .blur {
    opacity: 0.3;
  }

  .quote {
    border: 2px solid #007CC2;
    padding: 40px 80px;
    margin: 0 30px;
    position: relative;

    h4 {
      color: #007CC2;
      font-weight: 700;
    }

    .icon {
      position: absolute;
      top: 20px;
      left: -40px;
      width: 80px;
      height: 80px;
      z-index: 5;
    }

  }
}
.button {
  width: 80px;
  height: 80px;
  padding: 5px 5px 5px 10px;
  margin-top: 48px;

  &:first-of-type {
    padding: 5px 10px 5px 5px;
    margin-right: 50px;
  }

  &:hover {
    background-color: lightgray;
    border-radius: 50%;
    cursor: pointer;
  }
  &:active {
    background-color: gray;
    border-radius: 50%;
    color: white
  }
}
</style>