<template>
  <section class="text-slider">
    <b-container>
      <b-row align-h="center">
        <b-col class="" cols="12" xl="10">
          <div class="text-slider__wrapper d-flex flex-column" :style="payload.section_color ? {'background-color': payload.section_color} : ''">
            <h3 class="text-slider__title">{{ payload.title }}</h3>
            <div class="text-slider__text" v-html="payload.text"></div>
            <div class="text-slider__swiper">
              <div class="swiper text-slider-swiper">
                <div class="swiper-wrapper">
                  <div class="text-slider__card swiper-slide" v-for="(slide, index) in payload.slides" :key="index" ref="slides">
                    <h5 class="text-slider__card-title">{{ slide.title }}</h5>
                    <div class="text-slider__card-box d-flex flex-column align-items-center">
                      <img class="text-slider__card-img" :src="slide.icon.url" :alt="slide.icon.alt" />
                      <div class="text-slider__content">
                        <div class="text-slider__description" ref="description" v-html="slide.text"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-slider__nav">
                <div class="d-flex">
                  <div class="text-slider__nav-arrows mr-4">
                    <svg class="text-slider__nav-left" width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M20 8.42871L12 16.4287L20 24.4287" stroke="#111111" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <svg class="text-slider__nav-right" width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 8.42871L20 16.4287L12 24.4287" stroke="#111111" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';
import 'swiper/swiper-bundle.min.css';
export default {
  name: "TextSlider",
  props: ['payload'],
  mounted() {
    this.swiper = new Swiper('.text-slider-swiper', {
      modules: [Navigation],
      slidesPerView: 1,
      loop: false,
      slidesOffsetBefore: 0,
      slidesOffsetAfter: 0,
      spaceBetween: 32,
      centeredSlides: true,
      breakpoints: {
        576: {
          centeredSlides: false,
          slidesPerView: 'auto'
        },
      },
      navigation: {
        nextEl: '.text-slider__nav-right',
        prevEl: '.text-slider__nav-left',
      },
    });
  },
  methods: {
  },
  beforeDestroy() {
    if (this.swiper) {
      this.swiper.destroy(true, true);
    }
  },
}
</script>

<style scoped lang="scss">
.text-slider {
  padding-bottom: 70px;
  padding-top: 70px;
  background: $main-bg;
  color: $main-color;

  @include media-breakpoint-down(sm) {
    padding: 70px 0;
  }

  &__wrapper {
    position: relative;
    padding: 64px;
    background-color: $main-blue;
    border-radius: 20px;

    @include media-breakpoint-down(sm){
      margin-bottom: 24px;
      padding: 42px 20px;
    }
  }

  &__title {
    font-size: 1.75em;
    font-weight: 700;
    line-height: 120%;
    margin-bottom: 40px;
    @include media-breakpoint-down(lg){
      margin-bottom: 24px;
    }
  }

  &__text {
    font-size: 1.059em;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    margin-bottom: 24px;
    @include media-breakpoint-down(lg){
      margin-bottom: 8px;
    }
  }

  &__label {
    position: absolute;
    top: 30px;
    right: 30px;
    padding: 8px;
    background-color: $white;
    border-radius: 4px;
    box-shadow: -1px 2px 8px 0px rgba(0, 0, 0, 0.15);

    & span {
      font-size: .875em;
      font-weight: 500;
      letter-spacing: 0.028px;
    }

    & .hash {
      font-size: 1em;
      font-weight: 500;
      letter-spacing: 0.028px;
    }
  }

  &__card {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 576px;
    height: auto;
    min-height: 272px;
    padding: 24px 48px;
    border-radius: 16px;
    background-color: $white;
    overflow: hidden;

    @include media-breakpoint-down(sm){
      padding: 24px 16px;
    }

    &-img {
      z-index: -1;
      position: absolute;
      bottom: 0;
      right: 0;
      max-width: 200px;
      object-fit: cover;
      transform: translate(15%, 15%);
    }

    &-title {
      margin-bottom: 24px;
    }
  }

  &__description {
    font-size: 1em;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
  }

  &__nav {
    //position: absolute;
    //bottom: 64px;
    //right: 64px;
    margin-top: 40px;
    display: flex;
    justify-content: flex-end;
  }

  .text-slider__nav-left,
  .text-slider__nav-right,
  .text-slider__nav-pause {

    &:hover {
      cursor: pointer;
    }
  }
}
</style>
