<template>
  <section class="list-materials">
    <b-container>
      <b-row align-h="center">
        <b-col cols="12" xl="10" class="d-flex flex-column list-materials__wrapper">
          <div v-if="payload.materials.length > 0">
            <h2 v-if="payload.title" class="list-materials__title">{{ payload.title }}</h2>
            <article class="list-materials__card" v-for="(content, index) in payload.materials" :key="index">
              <div class="list-materials__card-content">
                <h5 class="list-materials__card-title">
                  {{content.title}}
                </h5>
                <a class="list-materials__card-icon" href="#" @click.prevent="downloadFile(content.file.url, content.file.name)" target="_blank">
                  {{content.text_action}}
                  <svg class="ml-1" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16.59 9.15356H15V4.15356C15 3.60356 14.55 3.15356 14 3.15356H10C9.45 3.15356 9 3.60356 9 4.15356V9.15356H7.41C6.52 9.15356 6.07 10.2336 6.7 10.8636L11.29 15.4536C11.68 15.8436 12.31 15.8436 12.7 15.4536L17.29 10.8636C17.92 10.2336 17.48 9.15356 16.59 9.15356ZM5 19.1536C5 19.7036 5.45 20.1536 6 20.1536H18C18.55 20.1536 19 19.7036 19 19.1536C19 18.6036 18.55 18.1536 18 18.1536H6C5.45 18.1536 5 18.6036 5 19.1536Z" fill="#393947"/>
                  </svg>
                </a>
              </div>
            </article>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>
<script>

export default {
  name: 'ListMaterials',
  props: ['payload'],
  components: {},
  data() {
    return {
      index: 0
    }
  },
  methods: {
    async downloadFile(fileUrl, fileName) {
      try {
        // Fetch the file from the URL as a blob
        const response = await fetch(fileUrl);
        const blob = await response.blob();

        // Create a URL for the blob
        const blobUrl = window.URL.createObjectURL(blob);

        // Create a temporary anchor element
        const link = document.createElement('a');
        link.href = blobUrl;

        // Set the download attribute with the file name
        link.download = fileName || 'download';

        // Append the anchor to the body
        document.body.appendChild(link);

        // Trigger the download
        link.click();

        // Cleanup: Remove the link and revoke the object URL
        document.body.removeChild(link);
        window.URL.revokeObjectURL(blobUrl);
      } catch (error) {
        console.error('Error downloading file:', error);
      }
    }
  }
}
</script>
<style scoped lang="scss">

.list-materials {
  position: relative;
  background: $main-bg;
  color: $main-color;
  padding-top: 70px;
  padding-bottom: 70px;

  & .list-materials__card:not(:last-child) {
    border-bottom: 1px solid #D4D4D4;
  }

  .row {
    @include media-breakpoint-down(sm){
      margin: 0;
    }
  }

  &__wrapper {
    padding: 64px;
    background: $white;
    border-radius: 20px;
    z-index: 2;

    @include media-breakpoint-down(sm){
      padding: 32px 20px;
    }
  }

  &__title {
    font-size: 1.75em;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    margin-bottom: 40px;

    @include media-breakpoint-down(lg){
      margin-bottom: 20px;
    }
  }

  &__card {
    display: flex;
    padding: 16px 12px;

    &-title {
      font-size: 24px;
      margin-bottom: 0;
      font-size: 1.125em;
      font-style: normal;
      font-weight: 500;
    }

    &-content {
      display: flex;
      justify-content: space-between;
      width: 100%;

      @include media-breakpoint-down(sm){
        flex-direction: column;
        gap: 16px;
      }
    }

    &-icon {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-size: .88em;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      text-decoration: none;
      color: $main-color;
    }
  }
}

</style>
